import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: navigator.language,
  fallbackLocale: 'en',
  messages: {
    'en': {
      "auth---Login": "Login",
      "auth---Next": "Next",
      "auth---Username, email or phone number": "Username, email or phone number",
      "auth---I forgot everything": "I forgot everything",
      "auth---I forgot my password": "I forgot my password",
      "auth---Login as student": "Login as student",
      "auth---User does not exist": "User does not exist",
      "auth---Wrong password": "Wrong password",
      "auth---Wrong code": "Wrong code",
      "auth---No active code": "You don't have active code",
      "auth---Too many attempts": "Too many attempts",
      "auth---You need to log in again.": 'You need to <a href="/">log in</a> again.',
      "auth---Something went wrong": "Something went wrong",
      "auth---Enter": "Enter",
      "auth---Continue": "Continue",
      "auth---Welcome, {username}!": "Welcome, {username}!",
      "auth---Welcome!": "Welcome!",
      "auth---Enter password": "Enter password",
      "auth---I remembered my password": "I remembered my password",
      "auth---Do not worry, {username}!": "Do not worry, {username}!",
      "auth---Do not worry!": "Do not worry!",
      "auth---Set your profile. You can use these credentials instead of username.": "Set your profile. You can use these credentials instead of username.",
      "auth---Email": "Email",
      "auth---Phone number": "Phone number",
      "auth---Add": "Add",
      "auth---Set password": "Set password",
      "auth---Unfortunately, authorization failed": "Unfortunately, authorization failed"
    },
    'ru': {
      "auth---Login": "Вход",
      "auth---Next": "Дальше",
      "auth---Username, email or phone number": "Логин, эл.почта или номер телефона",
      "auth---I forgot everything": "Я всё забыл(а)",
      "auth---I forgot my password": "Я не помню пароль",
      "auth---Login as student": "Войти как ученик",
      "auth---User does not exist": "Такой пользователь не зарегистрирован",
      "auth---Wrong password": "Неверный пароль",
      "auth---Wrong code": "Неверный код",
      "auth---No active code": "У вас нет активного кода",
      "auth---Too many attempts": "Превышено количество попыток входа",
      "auth---You need to log in again.": 'Вам нужно <a href="/">авторизоваться</a> заново.',
      "auth---Something went wrong": "Что-то пошло не так",
      "auth---Enter": "Войти",
      "auth---Continue": "Продолжить",
      "auth---Welcome, {username}!": "Добро пожаловать, {username}!",
      "auth---Welcome!": "Добро пожаловать!",
      "auth---Enter password": "Введите пароль",
      "auth---I remembered my password": "Я вспомнил пароль",
      "auth---Do not worry, {username}!": "Не расстраивайтесь, {username}!",
      "auth---Do not worry!": "Не расстраивайтесь!",
      "auth---Set your profile. You can use these credentials instead of username.": "Добавьте свои контактные данные. Вы так же сможете использовать их вместо логина.",
      "auth---Email": "Эл. почта",
      "auth---Phone number": "Телефон",
      "auth---Add": "Добавить",
      "auth---Set password": "Установить пароль",
      "auth---Unfortunately, authorization failed": "К сожалению, авторизация недоступна",
    },
    'az': {
      "auth---Next": "Növbəti",
      "auth---Login": "Giriş",
      "auth---Username, email or phone number": "İstifadəçi adı, e-mail və ya telefon nömrəsi",
      "auth---I forgot everything": "Hər şeyi unutmuşam",
      "auth---I forgot my password": "Şifrəmi unutmuşam",
      "auth---Login as student": "Tələbə kimi daxil olun",
      "auth---User does not exist": "Bu adda istifadəçi qeydə alınmayıb",
      "auth---Wrong password": "Şifrə yanlışdır",
      "auth---Wrong code": "Kod yanlışdır",
      "auth---Enter": "Daxil olmaq",
      "auth---Continue": "Davam et",
      "auth---Welcome, {username}!": "Xoş gəldiniz, {username}!",
      "auth---Welcome!": "Xoş gəldiniz!",
      "auth---Enter password": "Şifrəni daxil edin",
      "auth---I remembered my password": "Şifrəmi xatırladım",
      "auth---Do not worry, {username}!": "Narahat olmayın, {username}!",
      "auth---Do not worry!": "Narahat olmayın!",
      "auth---Set your profile. You can use these credentials instead of username.": "Əlaqə bilgilərinizi daxil edin. Login əvəzinə onlardan istifadə edə bilərsiniz",
      "auth---Email": "E-poçt",
      "auth---Phone number": "Telefon nömrəsi",
      "auth---Add": "Əlavə etmək"
    },
    'ar': {
      "auth---Next": "التالى",
      "auth---Login": "تسجيل الدخول",
      "auth---Username, email or phone number": "اسم المستخدم أو البريد الإلكتروني أو رقم الهاتف",
      "auth---I forgot everything": "لقد نسيت كل شيء",
      "auth---I forgot my password": "لقد نسيت كلمة المرور",
      "auth---Login as student": "تسجيل الدخول كطالب",
      "auth---Enter": "أدخل",
      "auth---Welcome, {username}!": "مرحبًا ، {username}!",
      "auth---Welcome!": "مرحبًا!",
      "auth---Enter password": "أدخل كلمة المرور",
      "auth---I remembered my password": "تذكرت كلمة المرور الخاصة بي",
      "auth---Do not worry, {username}!": "لا تقلق ، {username}!",
      "auth---Do not worry!": "لا تقلق!",
      "auth---Set your profile. You can use these credentials instead of username.": "تعيين ملف التعريف الخاص بك، يمكنك استخدام بيانات الاعتماد هذه بدلاً من اسم المستخدم.",
      "auth---Email": "البريد الإلكتروني",
      "auth---Phone number": "رقم الهاتف",
      "auth---Add": "إضافة"
    },
    'he': {
      "auth---Next": "הבא",
      "auth---Login": "התחברות",
      "auth---Username, email or phone number": "שם משתמש, אימייל או מספר טלפון",
      "auth---I forgot everything": "שכחתי הכל",
      "auth---I forgot my password": "שכחתי את הסיסמא שלי",
      "auth---Login as student": "התחבר כסטודנט",
      "auth---User does not exist": "משתמש לא קיים",
      "auth---Wrong password": "סיסמה שגויה",
      "auth---Wrong code": "קוד שגוי",
      "auth---Enter": "להיכנס",
      "auth---Continue": "המשך",
      "auth---Welcome, {username}!": "ברוכים הבאים, {username}!",
      "auth---Welcome!": "ברוכים הבאים!",
      "auth---Enter password": "הזן את הסיסמה",
      "auth---I remembered my password": "נזכרתי סיסמתי",
      "auth---Do not worry, {username}!": "אל תדאג, {username}!",
      "auth---Do not worry!": "אל תדאג!",
      "auth---Set your profile. You can use these credentials instead of username.": "הגדר את הפרופיל שלך. אתה יכול להשתמש באישורים אלה במקום את שם המשתמש.",
      "auth---Email": "אימייל",
      "auth---Phone number": "מספר טלפון",
      "auth---Add": "הוספה"
    },
    'tr': {
      "auth---Next": "Sonraki",
      "auth---Login": "Oturum Aç",
      "auth---Username, email or phone number": "Kullanıcı adı, e-posta veya telefon numarası",
      "auth---I forgot everything": "Her şeyi unuttum",
      "auth---I forgot my password": "Şifremi Unuttum",
      "auth---Login as student": "Öğrenci olarak giriş yap",
      "auth---User does not exist": "Böyle bir kullanıcı yok",
      "auth---Wrong password": "Hatalı şifre",
      "auth---Wrong code": "Yanlış kod",
      "auth---Enter": "Giriş",
      "auth---Continue": "Devam et",
      "auth---Welcome, {username}!": "Hoş geldin {username}!",
      "auth---Welcome!": "Hoş geldin!",
      "auth---Enter password": "Şifre gir",
      "auth---I remembered my password": "Şifremi hatırladım",
      "auth---Do not worry, {username}!": "Endişelenme, {username}!",
      "auth---Do not worry!": "Endişelenme!",
      "auth---Set your profile. You can use these credentials instead of username.": "Profilinizi ayarlayın. Bu kimlik bilgilerini kullanıcı adı yerine kullanabilirsiniz.",
      "auth---Email": "E-posta",
      "auth---Phone number": "Telefon numarası",
      "auth---Add": "Ekle"
    },
    'es': {
      "auth---Login": "Iniciar sesión",
      "auth---Next": "Siguiente",
      "auth---Username, email or phone number": "Nombre de usuario, correo electrónico o número de teléfono",
      "auth---I forgot everything": "Yo olvidé todo",
      "auth---I forgot my password": "Olvidé mi contraseña",
      "auth---Login as student": "Iniciar sesión como estudiante",
      "auth---User does not exist": "El usuario no existe",
      "auth---Wrong password": "Contraseña incorrecta",
      "auth---Wrong code": "Codigo erroneo",
      "auth---No active code": "No tienes ningún código activo.",
      "auth---Too many attempts": "Demasiados intentos",
      "auth---You need to log in again.": 'Necesitas <a href="/">iniciar sesión</a> de nuevo.',
      "auth---Something went wrong": "Algo salió mal",
      "auth---Enter": "Ingresar",
      "auth---Continue": "Continuar",
      "auth---Welcome, {username}!": "Bienvenido, {username}!",
      "auth---Welcome!": "¡Bienvenidos!",
      "auth---Enter password": "Introducir la contraseña",
      "auth---I remembered my password": "Recordé mi contraseña",
      "auth---Do not worry, {username}!": "No te preocupes, {username}!",
      "auth---Do not worry!": "¡No te preocupes!",
      "auth---Set your profile. You can use these credentials instead of username.": "Establece tu perfil. Puede usar estas credenciales en lugar del nombre de usuario.",
      "auth---Email": "Email",
      "auth---Phone number": "Número de teléfono",
      "auth---Add": "Agregar",
      "auth---Set password": "Fijar contraseña",
      "auth---Unfortunately, authorization failed": "Desafortunadamente, la autorización falló"
    },
  }
});

export default i18n;
